// React and Styles
import React from "react";
import styles from "./styles.module.css";

// Plugins and Modules

// Components and Utils
import logo from "../../assets/logo.png";
import title from "../../assets/title.png";
import icon1 from "../../assets/icon_1.png";
import icon2 from "../../assets/icon_2.png";
import { useCard } from "../../hooks/useCard";
import mixpanel from "mixpanel-browser";

const Header = () => {
  const { setModal } = useCard();

  return (
    <div className={styles.wrapper}>
      <div className={styles.logos}>
        {[
          ["logo", logo, "Boo"],
          ["title", title, "Icon"],
        ].map(([name, src, type]) => (
          <a
            key={name}
            target="_blank"
            rel="noreferrer"
            href="https://schmooze.in/"
          >
            <img
              src={src}
              alt={name}
              className={styles[name]}
              onClick={() => mixpanel.track(`Schmooze ${type} Button`)}
            />
          </a>
        ))}
      </div>
      <div className={styles.icons}>
        {[
          ["+", icon1, "UGC"],
          ["*", icon2, "Meme Vibe"],
        ].map(([alt, src, type]) => (
          <img
            key={alt}
            src={src}
            alt={alt}
            className={styles.icon}
            onClick={() => {
              setModal(true);
              mixpanel.track(`${type} Button`);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Header;
