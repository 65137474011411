// React and Styles
import React, { Fragment } from "react";

// Plugins and Modules
import { Modal } from "antd";

// Components and Utils
import Share from ".";
import { useCard } from "../../hooks/useCard";

const Box = () => {
  const { modal, setModal } = useCard();

  return (
    <Modal
      centered
      open={modal}
      footer={null}
      className="qrmodal"
      closeIcon={<Fragment />}
      onOk={() => setModal(false)}
      onCancel={() => setModal(false)}
      maskStyle={{ background: "rgba(0, 0, 0, 0.80)" }}
    >
      <Share />
    </Modal>
  );
};

export default Box;
