// React and Styles
import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";

// Plugins and Modules
import { Image } from "antd";

// Components and Utils
import logo from "../../assets/logo-filled.png";
import card from "../../assets/qrcard.svg";
import instagram from "../../assets/instagram.png";
import twitter from "../../assets/twitter.png";
import threads from "../../assets/threads.png";
import linkedin from "../../assets/linkedin.png";
import youtube from "../../assets/youtube.png";
import { usePost } from "../../hooks/usePost";
import { useUgcMeme } from "../../hooks/useUgcMeme";
import mixpanel from "mixpanel-browser";

const Share = ({ code, isUgc }) => {
  const { getDataByCode } = usePost();
  const { getUgcDataByCode } = useUgcMeme();
  const [link, setLink] = useState("https://link.schmooze.tech/q0DU/0a545kbp");

  useEffect(() => {
    const fetchPost = async () => {
      let data;
      if (isUgc) {
        data = await getUgcDataByCode(code);
      } else {
        data = await getDataByCode(code);
      }
      setLink(data?.invite_url);
    };

    if (code && !code.includes("apple-touch-icon")) fetchPost();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <div className={styles.wrapper}>
      <img src={logo} alt="SCHMOOZE" className={styles.logo} />
      <div className={styles.text1}>Frustrated with dating apps?</div>
      <div className={styles.text2}>
        Try Schmooze, Not your regular dating app
      </div>
      <img src={card} alt="" className={styles.card} />
      <div className={styles.text3}>Scan QR to get Schmooze</div>
      <div className={styles.buttons}>
        <a href={link} target="_blank" rel="noreferrer">
          <button
            className={styles.big}
            onClick={() => mixpanel.track("Get Schmooze App")}
          >
            Download Schmooze
          </button>
        </a>
      </div>
      <div className={styles.text4}>Follow us on</div>
      <div className={styles.icons}>
        {[
          ["Instagram", instagram, "com/schmooze.or.snooze/"],
          ["Twitter", twitter, "com/schmoozeorsnooz"],
          ["Threads", threads, "net/@schmooze.or.snooze"],
          ["LinkedIn", linkedin, "com/company/schmooze-dating"],
          ["Youtube", youtube, "com/@schmooze.or.snooze"],
        ].map(([alt, src, lnk]) => (
          <a
            key={alt}
            target="_blank"
            rel="noreferrer"
            href={`https://www.${alt}.${lnk}`}
          >
            <Image
              src={src}
              alt={alt}
              preview={false}
              className={styles.icon}
              onClick={() => mixpanel.track(`Follow Option: ${alt}`)}
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Share;
