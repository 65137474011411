import axios from "axios";

const API = axios.create({
  baseURL: `https://drogon.schmooze.tech/v3/ugc/memes/`,
});

const getUgcDataByCode = async (code) => {
  try {
    const res = await API(`/citadel/preview/${code}`);
    return res?.data?.data;
  } catch (error) {
    return null;
  }
};

export const useUgcMeme = () => {
  return { getUgcDataByCode };
};
