// React and Styles
import React from "react";
import "./index.css";
import "./fonts.css";
import "./colors.css";

// Plugins and Modules
import {
  createBrowserRouter as CBR,
  RouterProvider as RP,
} from "react-router-dom";
import ReactDOM from "react-dom/client";
import mixpanel from "mixpanel-browser";

// Components and Utils
import Main from "./main";
import Error from "./404";
import Box from "./components/Share/modal";
import { CardProvider } from "./hooks/useCard";

const router = CBR([
  {
    path: "/u/:code",
    element: <Main isUgc={true} />,
  },
  {
    path: "/:code",
    element: <Main />,
  },
  {
    path: "*",
    element: <Error />,
  },
]);

mixpanel.init("74cec2453219d83f7571786c090dbb8d");

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <CardProvider>
      <Box />
      <RP router={router} />
    </CardProvider>
  </React.StrictMode>
);
