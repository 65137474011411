// React and Styles
import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";

// Plugins and Modules
import { Image } from "antd";
import { Blurhash } from "react-blurhash";
import { useWindowSize } from "rooks";

// Components and Utils
import meme from "../../assets/solid.svg";
import icon from "../../assets/cross.svg";
import muted1 from "../../assets/muted_1.svg";
import muted2 from "../../assets/muted_2.svg";
import unmuted1 from "../../assets/unmuted_1.svg";
import unmuted2 from "../../assets/unmuted_2.svg";
import share from "../../assets/share.svg";
import mark from "../../assets/bookmark.svg";
import play from "../../assets/play.svg";
import { useCard } from "../../hooks/useCard";
import { usePost } from "../../hooks/usePost";
import mixpanel from "mixpanel-browser";
import { useUgcMeme } from "../../hooks/useUgcMeme";

const Meme = ({ code, isUgc }) => {
  const { setModal } = useCard();
  const { getDataByCode } = usePost();
  const { getUgcDataByCode } = useUgcMeme();
  const { innerWidth } = useWindowSize();

  const [memeData, setMemeData] = useState({ hash: "9VLzs+_N" });

  useEffect(() => {
    const fetchPost = async () => {
      let data;
      if (isUgc) {
        data = await getUgcDataByCode(code);
      } else {
        data = await getDataByCode(code);
      }

      // const data = await getDataByCode(code);
      // const DATA = data?.post_details;
      let DATA;
      if (isUgc) {
        DATA = data?.ugc_meme_details;
      } else {
        DATA = data?.post_details;
      }

      setMemeData({
        hash: DATA.content_blurhash ? DATA?.content_blurhash : "9VLzs+_N",
        src:
          DATA?.content_url ??
          "https://seranking.com/blog/wp-content/uploads/2021/01/404_01-min.jpg",
        icon: DATA?.platform_url,
        name: DATA?.handle_name ?? "N/A",
        audio: DATA?.has_audio ? true : false,
        video: DATA?.type === "video" ? true : false,
      });
    };

    if (code && !code.includes("apple-touch-icon")) fetchPost();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, isUgc]);

  // VIDEO PREVIEW CONTROLS

  const mainRef = useRef(null);
  const videoRef = useRef(null);

  const [visible, setVisible] = useState(false);
  const [isMuted, setIsMuted] = useState({ main: true, video: true });
  const [isPlaying, setIsPlaying] = useState({ main: true, video: true });

  const togglePlayPause = (type) => {
    if (type === "main") setIsPlaying({ ...isPlaying, main: !isPlaying.main });
    if (type === "video")
      setIsPlaying({ ...isPlaying, video: !isPlaying.video });
  };

  useEffect(() => {
    if (mainRef.current) {
      if (isPlaying.main) mainRef.current.play();
      else mainRef.current.pause();

      mainRef.current.muted = isMuted.main;
    }

    if (videoRef.current) {
      if (isPlaying.video) videoRef.current.play();
      else videoRef.current.pause();

      videoRef.current.muted = isMuted.video;
    }
  }, [isPlaying, isMuted]);

  const toggleMute = (type) => {
    if (type === "main") setIsMuted({ ...isMuted, main: !isMuted.main });
    if (type === "video") setIsMuted({ ...isMuted, video: !isMuted.video });
  };

  useEffect(() => {
    if (mainRef.current) {
      if (visible) {
        mainRef.current.pause();
        setIsPlaying({ ...isPlaying, main: false });

        mainRef.current.muted = true;
        setIsMuted({ ...isMuted, main: true });
      } else {
        mainRef.current.play();
        setIsPlaying({ ...isPlaying, main: true });
      }
    }

    if (videoRef.current) {
      if (visible) {
      } else {
        videoRef.current.muted = true;
        setIsMuted({ ...isMuted, video: true });
      }
    }

    if (visible) mixpanel.track("Expand Meme");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {memeData?.icon && <img src={memeData?.icon} alt="icon" />}
        <span>{memeData?.name}</span>
      </div>

      {memeData?.video ? (
        <>
          <video
            loop
            muted
            autoPlay
            playsInline
            ref={mainRef}
            className={styles.meme}
            onClick={() =>
              innerWidth > 850 ? togglePlayPause("main") : setVisible(true)
            }
          >
            <source src={memeData?.src} type="video/mp4" />
          </video>
          <img
            alt="M"
            className={styles.mute}
            src={isMuted.main ? muted2 : unmuted2}
            onClick={() => toggleMute("main")}
          />
          {isPlaying.main || innerWidth < 850 ? null : (
            <img
              src={play}
              alt="play"
              className={styles.play}
              style={{ pointerEvents: "none" }}
            />
          )}
          <Image
            alt=""
            src=""
            style={{ display: "none" }}
            preview={
              innerWidth > 850
                ? false
                : {
                    visible: visible,
                    toolbarRender: () => null,
                    onVisibleChange: (vis) => setVisible(vis),
                    imageRender: () => (
                      <div style={{ position: "relative" }}>
                        <video
                          loop
                          muted
                          autoPlay
                          playsInline
                          ref={videoRef}
                          onClick={() => togglePlayPause("video")}
                          style={{
                            maxWidth: "100vw",
                            maxHeight: "calc(100vh - 200px)",
                          }}
                        >
                          <source src={memeData?.src} type="video/mp4" />
                        </video>
                        <img
                          alt="M"
                          className={styles.vmute}
                          src={isMuted.video ? muted1 : unmuted1}
                          onClick={() => toggleMute("video")}
                        />
                        {isPlaying.video ? null : (
                          <img
                            src={play}
                            alt="play"
                            className={styles.play}
                            style={{ pointerEvents: "none" }}
                          />
                        )}
                      </div>
                    ),
                    closeIcon: (
                      <>
                        <div className={styles.close}>
                          Swipe memes, not people
                        </div>
                        <img src={icon} alt="icon" className={styles.cross} />
                      </>
                    ),
                  }
            }
          />
        </>
      ) : (
        <Image
          alt={`meme-${code}`}
          src={memeData?.src || meme}
          className={styles.meme}
          preview={
            innerWidth > 850
              ? false
              : {
                  visible: visible,
                  onVisibleChange: (vis) => setVisible(vis),
                  toolbarRender: () => null,
                  closeIcon: (
                    <>
                      <div className={styles.close}>
                        Swipe memes, not people
                      </div>
                      <img src={icon} alt="icon" className={styles.cross} />
                    </>
                  ),
                }
          }
        />
      )}
      <Blurhash
        className={styles.hash}
        hash={memeData?.hash}
        resolutionX={32}
        resolutionY={32}
        punch={1}
      />
      <div
        className={styles.hash}
        style={{ zIndex: 0, pointerEvents: "none" }}
      />
      {["Share", "Bookmark"].map((alt, i) => (
        <img
          key={i}
          alt={alt}
          src={i ? mark : share}
          className={styles[alt?.toLowerCase()]}
          onClick={() => {
            setModal(true);
            mixpanel.track(`${alt} Button`);
          }}
        />
      ))}
    </div>
  );
};

export default Meme;
