import React, { useState, useContext, createContext } from "react";

const CardContext = createContext();

export const CardProvider = ({ children }) => {
  const [modal, setModal] = useState(false);

  return (
    <CardContext.Provider value={{ modal, setModal }}>
      {children}
    </CardContext.Provider>
  );
};

export const useCard = () => useContext(CardContext);
