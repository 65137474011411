// React and Styles
import React from "react";
import "./index.css";

// Plugins and Modules
import { useWindowSize } from "rooks";

// Components and Utils
import Header from "./components/Header";
import Share from "./components/Share";
import Footer from "./components/Footer";

const GIF_URL =
  "https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif";

const Error = () => {
  const { innerWidth } = useWindowSize();

  return (
    <main>
      <Header />
      <img
        alt="404"
        src={GIF_URL}
        style={{ maxWidth: "80vw", borderRadius: 20, maxHeight: "70vh" }}
      />
      {innerWidth > 850 ? <Share /> : <Footer />}
    </main>
  );
};

export default Error;
