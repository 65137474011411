// React and Styles
import React from "react";
import "./index.css";

// Plugins and Modules
import { useWindowSize } from "rooks";

// Components and Utils
import Header from "./components/Header";
import Meme from "./components/Meme";
import Share from "./components/Share";
import Footer from "./components/Footer";
import { useParams, useLocation } from "react-router-dom";

const Main = ({ isUgc = false }) => {
  const { code } = useParams();
  const { innerWidth } = useWindowSize();

  // const location = useLocation();
  // const isUgc = location.pathname.startsWith("/u/");

  return (
    <main>
      <Header />
      <Meme code={code} isUgc={isUgc} />
      {innerWidth > 850 ? (
        <Share code={code} isUgc={isUgc} />
      ) : (
        <Footer code={code} isUgc={isUgc} />
      )}
    </main>
  );
};

export default Main;
