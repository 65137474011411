// React and Styles
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";

// Plugins and Modules
import { useWindowSize } from "rooks";

// Components and Utils
// import up from "../../assets/up.svg";
// import down from "../../assets/down.svg";
import logo from "../../assets/logo-filled.png";
import { usePost } from "../../hooks/usePost";
import mixpanel from "mixpanel-browser";
import { useUgcMeme } from "../../hooks/useUgcMeme";

const Footer = ({ code, isUgc }) => {
  const { innerWidth } = useWindowSize();
  const { getDataByCode } = usePost();
  const { getUgcDataByCode } = useUgcMeme();

  const [open, setOpen] = useState(false);
  const [link, setLink] = useState("https://link.schmooze.tech/q0DU/0a545kbp");

  useEffect(() => {
    if (650 <= innerWidth && innerWidth <= 850) setOpen(true);
  }, [innerWidth]);

  useEffect(() => {
    const fetchPost = async () => {
      let data;
      if (isUgc) {
        data = await getUgcDataByCode(code);
      } else {
        data = await getDataByCode(code);
      }
      // const data = await getDataByCode(code);
      setLink(data?.invite_url);
    };

    if (code && !code.includes("apple-touch-icon")) fetchPost();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const get = () => mixpanel.track("Get App");

  return (
    <div className={styles.wrapper}>
      {/* <button className={styles.control} onClick={() => setOpen(!open)}>
        <img
          alt=""
          src={open ? down : up}
          className={`${styles.arr} ${open ? styles.arrd : styles.arra}`}
        />
      </button> */}
      <div className={styles.open} onClick={() => setOpen(!open)}>
        <div className={styles.top}>
          <img src={logo} alt="SCHMOOZE" className={styles.logo} />
          <div className={styles.content}>
            <div className={styles.title}>
              Want to find dates while swiping memes like this?
            </div>
            {/* <div className={styles.text}>
              Try Schmooze, Not your regular dating app!
            </div> */}
          </div>
        </div>
        <div className={styles.bottom}>
          <a href={link} target="_blank" rel="noreferrer">
            <button className={styles.button} onClick={get}>
              Download Schmooze
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
