import axios from "axios";

const API = axios.create({ baseURL: `https://drogon.schmooze.tech/v2/posts/` });

const getDataByCode = async (code) => {
  try {
    const res = await API(`/preview/${code}`);
    return res?.data?.data;
  } catch (error) {
    return null;
  }
};

export const usePost = () => {
  return { getDataByCode };
};
